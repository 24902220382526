@import "../global-styles/terra.scss";

.ter-link-card {
  &__header {
    margin-bottom: spacing("xs");
    @include opensans-very-bold();
  }
  &__text {
    font-size: font-size("sm");
    margin-bottom: spacing("mini");
  }

  &__link {
    margin-top: 0;
    font-size: font-size("sm");
    @include opensans-medium();
  }

  .ter-icon {
    fill: color("ui-1");
    margin-left: spacing("mini");
    transform: translateY(10px);
  }
}
