@import "../../../global-styles/terra.scss";

.ter-mega-menu {
  height: 72px;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;

  &:hover {
    cursor: pointer;
  }

  &:hover .ter-mega-menu__nav-text {
    background: $mep-gray-2;
  }

  &__title-link {
    text-decoration: none;
  }

  &__nav-text {
    color: $body-font-color;

    font-size: 14px;
    margin: 11px 0 0;
    padding: 10px 16px 0;
    position: relative;
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
    height: 53px;
    -ms-flex-item-align: end;
    -ms-grid-row-align: end;
    align-self: end;
    @include opensans-medium();
    border-top: 2px solid transparent;

    &--is-open {
      border-top: 2px solid $mep-blue-2;
    }

    img {
      height: 8px;
      padding-left: 6px;
      margin: auto;
    }
  }

  &__dropdown {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    color: gray("gray-1") !important;
    background: $mep-gray-2;
    cursor: pointer;
    position: absolute;
    width: 800px;
    max-height: 0;
    height: 0;
    padding: 0 30px;
    z-index: 60;
    margin: auto;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    overflow: hidden;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    opacity: 0;

    &--is-open {
      padding: 36px;
      height: 452px;
      min-height: 452px;
      opacity: 1;
    }
  }

  &__left-title {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    @include opensans-medium();
    padding: 8px 16px;
    margin: 0;
    font-size: font-size("micro");
    color: $body-font-color;
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
    position: relative;
    z-index: 2;

    &--is-open {
      background: $mep-blue-2;
      color: #f3f3f7;
      text-decoration: underline;
    }

    &-caret {
      height: 10px;
      float: right;
      -webkit-transform: translateY(6px);
      -ms-transform: translateY(6px);
      transform: translateY(6px);
      display: inline-block;
    }
  }

  &__right-section-placeholder {
  }

  &__left-section {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    border-right: 2px solid $mep-blue-2;
    position: relative;
    z-index: 2;

    &-bottom-link {
      p {
        font-size: 16px;
        font-weight: 600;
        position: absolute;
        bottom: 0;
        font-size: font-size("micro");
        color: $mep-gray-1;
        padding: 8px 32px;
        margin: 0;
        -webkit-transition: 0.2s;
        -o-transition: 0.2s;
        transition: 0.2s;
        z-index: 2;
        text-decoration: none;
        background: $mep-blue-1;

        &:hover {
          background: $mep-blue-2;
          text-decoration: underline;
        }
      }
    }
  }

  .ter-right-section {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    min-height: 400px;
    position: absolute;
    top: -36px;
    left: 334px;
    z-index: -1;
    width: 0;
    padding: 36px 0;
    opacity: 0;
    -webkit-transition: width 0.8s, padding 0.3s, left 0.8s, opacity 0s;
    -o-transition: width 0.8s, padding 0.3s, left 0.8s, opacity 0s;
    transition: width 0.8s, padding 0.3s, left 0.8s, opacity 0s;
    overflow: hidden;
    white-space: nowrap;

    &--is-open {
      width: 428px;
      padding: 36px 36px 36px 28px;
      opacity: 1;
    }

    &__link {
      display: block;
      font-size: font-size("micro");
      color: $mep-gray-3;
      padding: 8px 16px;
      margin: 0;
      -webkit-transition: 0.2s;
      -o-transition: 0.2s;
      transition: 0.2s;
      position: relative;
      z-index: 2;
      text-decoration: none;
      line-height: 1.6;
      padding: 8px 20px;

      img {
        height: 12px;
        float: right;
        -webkit-transform: translateY(4px);
        -ms-transform: translateY(4px);
        transform: translateY(4px);
        opacity: 0;
        -webkit-transition: 0.2s;
        -o-transition: 0.2s;
        transition: 0.2s;
      }

      &:hover img {
        opacity: 1;
      }

      &:hover {
        background: $mep-blue-2;
        color: gray(gray-1);
        text-decoration: underline;
      }
    }
  }
}
