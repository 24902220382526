@import "../../../global-styles/terra.scss";

.mini-feat-dropdown {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 calc((100vw - 1200px) / 2) 0;
  min-height: 228px;
  margin: 0 auto;
  background-color: color("brand-1");
  &__text-container {
    margin: 16px;
    max-width: 800px;
    position: relative;
    top: 16px;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }
  &__header {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: color("gray-1");
    @include header-standard-size;
    margin: 0;
    font-weight: bold;
  }

  &__text {
    color: color("gray-1");
    font-size: font-size("base");
    line-height: 32px;
    padding-top: 12px;
    margin: 0;
  }

  &__dropdown-label {
    margin-bottom: 0;
    @include opensans-medium();
    font-size: font-size("sm");
    color: color("gray-1");
  }

  &__dropdown-container {
    margin: 16px;
    position: relative;
    top: 8px;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 400px;
  }
}

@media screen and (max-width: 1200px) {
  .mini-feat-dropdown {
    &__text-container {
      margin: 32px;
    }
    &__dropdown-container {
      margin: 32px;
    }
  }
}

@media screen and (max-width: 775px) {
  .mini-feat-dropdown {
    display: -ms-grid;
    display: grid;
    -ms-grid-rows: 1fr 100px;
    grid-template-rows: 1fr 100px;

    &__dropdown-container {
      padding-bottom: 48px !important;
    }
  }
}

@media screen and (max-width: 460px) {
  .mini-feat-dropdown {
    &__text-container {
      width: 325px;
    }
    &__dropdown-container {
      width: 300px;
    }
  }
}

@media screen and (max-width: 365px) {
  .mini-feat-dropdown {
    &__text-container {
      width: 255px;
    }
    &__dropdown-container {
      width: 265px;
    }
  }
}
