@import "../../../global-styles/terra.scss";

.ter-list-feat-one {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;

  &__card-text {
    font-weight: font-weight("light");
  }

  @media screen and (max-width: 900px) {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  &--theme-4 {
    .ter-list-feat-one {
      &__card-text,
      &__card-header {
        color: color("gray-3");
      }
    }
  }

  &--theme-5 {
    .ter-list-feat-one {
      &__card-text,
      &__card-header {
        color: color("gray-3");
      }
    }
  }

  &__column-card {
    max-width: 600px;
    padding: 0 0 32px 0;

    &:first-child {
      padding-right: 32px;
    }

    &:nth-child(2) {
      padding-left: 32px;
    }

    @media screen and (max-width: 900px) {
      padding-bottom: 24px;
      max-width: 100%;
      &:first-child {
        padding-right: 0px;
      }

      &:nth-child(2) {
        padding-left: 0px;
      }
    }
  }

  &__card-header {
    margin-bottom: 16px;
    font-size: font-size("base");
    @include opensans-very-bold();
  }
}
