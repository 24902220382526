@import "../../../global-styles/terra.scss";

.ter-feat-one {
  margin: auto;
  @include organism-padding();
  position: relative;

  @media (min-width: $breakpoint-xs) {
    padding-top: spacing("mega");
  }

  @media (min-width: $breakpoint-sm) {
    @include grid-container;
  }

  &__background-image {
    position: absolute;
  }

  @media (min-width: $breakpoint-sm) {
    @include organism-padding();
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }

  &__image-wrapper {
    margin-bottom: spacing("md");

    @media (min-width: $breakpoint-sm) {
      @include grid-column(6);
      margin-bottom: 0;
    }
  }

  &__ratio-wrapper {
    position: relative;
    .ter-feat-one__image {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      object-fit: cover;
    }
  }

  &__image {
    width: 100%;
  }

  &__content-container {
    @media (min-width: $breakpoint-sm) {
      @include grid-column(6);
      padding-left: 16px;
    }

    @media (min-width: $breakpoint-md) {
      @include grid-column(6);
    }
  }

  &__header {
    @include header-standard-size;
  }

  &__sub-header {
    @include subheader-standard-size;
    font-weight: font-weight("light");
    color: gray("gray-7");
  }

  &__text,
  &__text-holder p {
    margin-top: spacing("md");
    margin-bottom: 0;
  }

  .ter-button {
    display: block;
    margin-top: spacing("md");

    @media (min-width: $breakpoint-xs) {
      display: inline-block;
      padding-right: spacing("lg");
      padding-left: spacing("lg");
    }

    @media (min-width: $breakpoint-md) {
      margin-top: spacing("md");
    }
  }

  &--theme-1 {
    background: color("theme-1");

    @include theme-1;

    h3,
    h4 {
      color: color("gray-7");
    }
  }

  &--theme-2 {
    background: color("theme-2");

    @include theme-2;

    h3,
    h4 {
      color: color("gray-7");
    }
  }

  &--theme-3 {
    background: color("theme-3");

    @include theme-3;

    h3,
    h4 {
      color: color("gray-7");
    }
  }

  &--theme-4 {
    @include theme-4;

    background: color("brand-2");

    h3,
    h4 {
      color: color("gray-1");
    }
  }

  &--theme-5 {
    @include theme-5;
    background: color("brand-1");

    h3,
    h4 {
      color: color("gray-1");
    }
  }
}