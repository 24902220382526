@import "../../../global-styles/terra.scss";

.ter-feat-four {
  @include organism-padding();
  margin: 0 auto;
  display: -ms-grid;
  display: grid;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;

  &__header {
    @include header-standard-size;
    text-align: center;
  }

  &__sub-header,
  &__sub-header-holder p {
    @include subheader-standard-size;
    font-weight: font-weight("light");
    text-align: center;
  }

  .react-reveal {
    display: -ms-grid;
    display: grid;
  }

  .ter-cta-section {
    @media screen and (min-width: $breakpoint-xs) {
      margin: auto;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      -ms-flex-pack: distribute;
          justify-content: space-around;
    }

    .ter-button {
      margin: spacing("sm") 0 0;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;

      @media screen and (min-width: $breakpoint-xs) {
        text-align: center;
        padding-left: spacing("lg");
        padding-right: spacing("lg");
        margin-left: spacing("sm");
        margin-right: spacing("sm");
      }
    }
  }

  &--theme-1 {
    @include theme-1;
    background: color("gray-1");
  }

  &--theme-2 {
    @include theme-2;
    background: color("gray-2");
  }

  &--theme-3 {
    @include theme-3;
    background: color("gray-3");
  }

  &--theme-4 {
    @include theme-4;
    background: color("brand-2");

    .ter-feat-four__sub-header-holder p,
    h2,
    h3,
    h4,
    button {
      @include brand-theme-text;
    }

    a.ter-button.ter-button--primary--1 {
      background-color: color("gray-2");
      color: color("brand-1");
    }
    a.ter-button.ter-button--primary--1:hover {
      background-color: color("gray-4") !important;
      color: color("brand-1") !important;
    }
    a.ter-button.ter-button--secondary--1 {
      border: 1px solid color("gray-2");
      color: color("gray-2") !important;
    }
    a.ter-button.ter-button--secondary--1:hover {
      background-color: color("gray-4") !important;
      color: color("brand-1") !important;
    }

    .ter-cta-section__link {
      @include brand-theme-link-text;
    }
    a {
      @include brand-link-text-deco;
    }
  }

  &--theme-5 {
    background: color("brand-1");

    @include theme-5;
    .ter-feat-four__sub-header-holder p,
    h2,
    h3,
    h4,
    button {
      @include brand-theme-text;
    }

    a.ter-button.ter-button--primary--1 {
      background-color: color("gray-2");
      color: color("brand-1");
    }
    a.ter-button.ter-button--primary--1:hover {
      background-color: color("gray-4") !important;
      color: color("brand-1") !important;
    }
    a.ter-button.ter-button--secondary--1 {
      border: 1px solid color("gray-2");
      color: color("gray-2") !important;
    }
    a.ter-button.ter-button--secondary--1:hover {
      background-color: color("gray-4") !important;
      color: color("brand-1") !important;
    }
    .ter-cta-section__link {
      @include brand-theme-link-text;
    }
    a {
      @include brand-link-text-deco;
    }
  }

  &__background-image {
    position: absolute;
  }
}


@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
   /* IE10+ specific styles go here */  
   .ter-feat-four {
      &__sub-header,
      &__sub-header-holder p {
        margin-top: 65px;
      }

       .ter-cta-section {
        margin: 130px;
       }
   }
}