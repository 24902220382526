@import "../../../global-styles/terra.scss";

.ftg-storefront-card {
  max-width: 1200px;
  max-height: 250px;
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 50px 1fr;
  grid-template-rows: 50px 1fr;
  -ms-grid-columns: 200px 1fr 1fr 200px;
  grid-template-columns: 200px 1fr 1fr 200px;
  background-color: color("gray-1");
  over-flow: hidden !important;

   p {
    color: color("gray-7");
   }

  &__title {
    line-height: 50px;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    grid-row: 1 / span 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 4;
    grid-column: 1 / span 4;
    background-color: color("brand-2");
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  &__title-text {
    font-size: 26px;
    margin: 10px;
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
    color: color("gray-1");
  }

  &__general-information {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-column: 1 / span 1;
  }

  &__details {
    display: -ms-grid;
    display: grid;
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    grid-column: 2 / span 1;
    margin: 20px;
  }

  &__image {
    margin: 20px;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-column: 1 / span 1;
    min-height: 160px;
    min-width: 160px;
    max-height: 160px;
    max-width: 160px;
  }

  &__header-text {
    height: 19px; width: 238px;
    width: 300px;
    color: color("gray-7"); 
    font-size: 16px; 
    font-weight: bold;
    line-height: 19px;
  }

  &__description-text {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    font-size: 16px;
    width: 350px;
    line-height: 19px;
  }

  &__link {
    -ms-flex-item-align: end;
        -ms-grid-row-align: end;
        align-self: end;
    font-size: 16px;
    line-height: 19px;
  }

  &__link:hover {
    text-decoration: none;
  }

  &__pricing {
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    grid-row: 2 / span 1;
    display: -ms-grid;
    display: grid;
    -ms-grid-column: 3 !important;
    -ms-grid-column-span: 1 !important;
    grid-column: 3 / span 1 !important;
  }

  &__pricing-item {
    margin: 25px 100px 0 0 !important;
  }

  &__price {
    text-align: center;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-column: 1 / span 1;
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    grid-row: 2 / span 1;
    font-size: 20px;
    line-height: 24px;
  }

  &__price-title, &__quantity-title {
    font-size: 12px;
    line-height: 14px;
  }

  &__price-title{
    text-align: center;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-column: 1 / span 1;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    grid-row: 1 / span 1;
    -ms-grid-row-align: end;
        align-self: end;

  }

  &__quantity-title{
    text-align: center;
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    grid-column: 2 / span 1;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    grid-row: 1 / span 1;
    -ms-grid-row-align: end;
        align-self: end;
  }

  &__quantity {
    text-align: center;
  }
  &__quantity, &__icon, {
    text-align: center;
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    grid-column: 2 / span 1;
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    grid-row: 2 / span 1;
  }

  &__plus {
    position: relative;
    left: 40px;
  }

   &__minus {
    position: relative;
    right: 40px;
  }

  &__minus, &__plus {
    -webkit-transform: translate(0, 2px);
        -ms-transform: translate(0, 2px);
            transform: translate(0, 2px);
    background-color: color("brand-2");
    cursor: pointer;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    line-height: 25px;
    text-align: center;
    border-radius: 50%;
    width: 25px;
    border: 0 solid black;
    z-index: 10;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    color: color("gray-1") !important;
  }

  .ter-button{
    cursor: pointer;
    -ms-grid-column-align: end;
        justify-self: end;
    -ms-grid-row-align: center;
        align-self: center;
    margin: 20px;
    height: 46px;
    -ms-grid-column: 4;
    -ms-grid-column-span: 1;
    grid-column: 4 / span 1;
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    grid-row: 2 / span 1;
    width: 144px;
    text-align: center;
    color: color("gray-1");
    background-color: color("brand-2");
    &__text {
      font-size: 16px;
      font-weight: bold;
      line-height: 16px;
      color: color("gray-1");
      margin: 0 auto;
      text-decoration: none;
    }
  }

  &__delivery-estimation {
    -ms-grid-column-align: end;
        justify-self: end;
    -ms-grid-row-align: end;
        align-self: end;
    margin-right: 20px;
    -ms-grid-column: 4;
    -ms-grid-column-span: 1;
    grid-column: 4 / span 1;
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    grid-row: 2 / span 1;
    font-size: 12px;
  }

}


@media only screen and (max-width: 1115px) {
  .ftg-storefront-card {
    &__pricing-item {
      margin: 25px 0 0 0 !important;
    }
  }
}

 @media only screen and (max-width: 1000px) {
  .ftg-storefront-card {
    min-height: 320px;
    -ms-grid-columns: 200px 1fr 1fr;
    grid-template-columns: 200px 1fr 1fr;
    &__pricing-item {
      -ms-grid-column-align: end;
          justify-self: end;
      margin-right: 20px !important;
    }

    &__header-text {
      width: auto;
    }

    &__description-text {
      width: auto;
    }
    .ter-button {
      width: 95%;
      -ms-grid-column: 1;
      -ms-grid-column-span: 4;
      grid-column: 1 / span 4;
      -ms-grid-row: 3;
      -ms-grid-row-span: 1;
      grid-row: 3 / span 1;
    }
    &__delivery-estimation {
      -ms-grid-column: 3;
      -ms-grid-column-span: 1;
      grid-column: 3 / span 1;
    }
  }
}

@media only screen and (max-width: 850px) {
   .ftg-storefront-card {
    min-height: 450px;
    -ms-grid-columns: 200px 1fr 1fr;
    grid-template-columns: 200px 1fr 1fr;
    -ms-grid-rows: 50px 1 fr 1fr 1fr;
    grid-template-rows: 50px 1 fr 1fr 1fr;
    
    &__pricing-item {
      -ms-grid-column-align: center;
          justify-self: center;
    }

    .ter-button {
      -ms-grid-column: 1;
      -ms-grid-column-span: 4;
      grid-column: 1 / span 4;
      -ms-grid-row: 4;
      -ms-grid-row-span: 1;
      grid-row: 4 / span 1;
    }

    &__details {
      -ms-grid-column: 2;
      -ms-grid-column-span: 2;
      grid-column: 2 / span 2;
    }

    &__pricing {
      -ms-grid-column: 1 !important;
      -ms-grid-column-span: 4 !important;
      grid-column: 1 / span 4 !important;
      -ms-grid-row: 3 !important;
      -ms-grid-row-span: 1 !important;
      grid-row: 3 / span 1 !important;
    }
    &__delivery-estimation {
      margin-bottom: 0;
      -ms-grid-column-align: center;
          justify-self: center;
      -ms-grid-column: 1;
      -ms-grid-column-span: 4;
      grid-column: 1 / span 4;
      -ms-grid-row: 3;
      -ms-grid-row-span: 1;
      grid-row: 3 / span 1;
    }
  }

}

@media only screen and (max-width: 575px) {
   .ftg-storefront-card {
    min-height: 600px;
    -ms-grid-columns: 1fr !important;
    grid-template-columns: 1fr !important;
    -ms-grid-rows: 50px 1fr 1fr 1fr 1fr;
    grid-template-rows: 50px 1fr 1fr 1fr 1fr;
    &__image {
      -ms-grid-column: 1;
      -ms-grid-column-span: 1;
      grid-column: 1 / span 1;
      -ms-grid-row: 2;
      -ms-grid-row-span: 1;
      grid-row: 2 / span 1;
      -ms-grid-row-align: center;
          align-self: center;
      -ms-grid-column-align: center;
          justify-self: center;
    }
    .ter-button {
      -ms-grid-row-align: center;
          align-self: center;
      -ms-grid-column-align: center;
          justify-self: center;
      -ms-grid-column: 1;
      -ms-grid-column-span: 1;
      grid-column: 1 / span 1;
      -ms-grid-row: 5;
      -ms-grid-row-span: 1;
      grid-row: 5 / span 1;
    }

    &__details {
      margin: 0 auto;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -ms-grid-column: 1;
      -ms-grid-column-span: 1;
      grid-column: 1 / span 1;
      -ms-grid-row: 3;
      -ms-grid-row-span: 1;
      grid-row: 3 / span 1;
    }

    &__header-text{
      margin: 12px auto;
      text-align: center;
    }


    &__description-text{
      padding-top: 12px;
      margin: 20px;
      text-align: center;
    }

    &__link {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
    }

    &__pricing {
      -ms-grid-column: 1 !important;
      -ms-grid-column-span: 1 !important;
      grid-column: 1 / span 1 !important;
      -ms-grid-row: 4 !important;
      -ms-grid-row-span: 1 !important;
      grid-row: 4 / span 1 !important;
    }
    &__delivery-estimation {
      -ms-grid-column-align: center;
          justify-self: center;
      -ms-grid-column: 1;
      -ms-grid-column-span: 1;
      grid-column: 1 / span 1;
      -ms-grid-row: 4;
      -ms-grid-row-span: 1;
      grid-row: 4 / span 1;
    }
  }
   
}