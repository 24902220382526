@import "../global-styles/terra.scss";

.ter-pagination {
  &__list {
    list-style: none;
    @include vendor-prefix-display-flex;
    text-align: center;
    @include vendor-prefix-justify-content-center;
  }
  &__list-item {
    margin: 0;
    padding: spacing("micro") spacing("xxs");
    font-size: font-size("sm");
    &:hover {
      cursor: pointer;
      border-bottom: 2px solid $pagination-border-hover-color;
    }
    &.is-active {
      border-bottom: 2px solid $pagination-border-active-color;
    }
  }
}