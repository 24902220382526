@import "../../../global-styles/terra.scss";

.ter-hero-6 {
  background-color: color("white");
  position: relative;

  @include organism-padding();

  &--right {
    .ter-hero-6__hero-image {
      right: 0;
      left: unset;
    }
    .ter-hero-6__content {
      -ms-grid-column-span: auto;
      grid-column-end: auto;
    }
  }
  &__hero-image {
    display: block;
    height: 100%;
    @media screen and (min-width: $breakpoint-sm) {
      background-size: cover;
      position: absolute;
      left: 0;
      top: 0;
      width: 54%;
      // height: auto;
    }
    img {
      width: 100%;
    }
    &--desktop {
      display: none;
      @media screen and (min-width: $breakpoint-md) {
        display: block;
      }
    }
    &--mobile {
      display: block;
      @media screen and (min-width: $breakpoint-sm) {
        display: none;
      }
    }
    &--tablet {
      display: none;
      @media screen and (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
        display: block;
      }
    }
    .hero-image-wrapper--large {
      display: none;
      @media screen and (min-width: $breakpoint-sm) {
        display: block;
        height: 100%;
      }
    }
  }
  &__container {
    @include grid-container();
    padding-right: 0;
    padding-left: 0;
  }
  &__content {
    padding: spacing("md") 0;
    @include grid-column(12);
    @media screen and (min-width: $breakpoint-sm) {
      @include grid-column(5);
      -ms-grid-column-span: -1;
      grid-column-end: -1;
      padding: spacing("mega") 0;
    }
  }

  &__header {
    @include header-standard-size;
  }
  &__sub-header {
    @include subheader-standard-size;
    font-weight: font-weight("light");
    margin-bottom: spacing("md");
  }

  .ter-cta-section {
    .ter-button {
      margin: spacing("xs") auto 0;
      text-align: center;
      display: block;

      @media (min-width: $breakpoint-xs) {
        margin: spacing("xs") 0 0;
      }
    }
  }

  .ter-cta-section__link {
    display: block;

    @media (min-width: $breakpoint-xs) {
      text-align: center;
    }
  }

  &--theme-1 {
    @include theme-1;
  }
  &--theme-2 {
    @include theme-2;
  }
  &--theme-3 {
    @include theme-3;
  }
  &--theme-4 {
    @include theme-4;
    h2,
    h3,
    h4,
    p {
      @include brand-theme-text;
    }
    .ter-cta-section__link {
      @include brand-theme-link-text;
    }
    a {
      @include brand-link-text-deco;
    }
  }
  &--theme-5 {
    @include theme-5;
    h2,
    h3,
    h4,
    p {
      @include brand-theme-text;
    }
    .ter-cta-section__link {
      @include brand-theme-link-text;
    }
    a {
      @include brand-link-text-deco;
    }
  }
}