@import "../global-styles/terra.scss";

.ter-radio {
  padding-top: spacing("xxs");
  padding-bottom: spacing("xxs");
  box-sizing: content-box;
  &__label {
    @include type-setting();
    font-size: $form-label-font-size;
    display: block;
    color: $form-label-color;
  }
  input[type="radio"] {
    opacity: 0;
    height: 0;
    width: 0;
    &:checked + label:after {
      transform: scale(1, 1);
    }
    &:focus + label:before {
      outline: 0;
    }
  }
  &__label {
    @include type-setting();
    font-size: $form-label-font-size;
    position: relative;
    display: inline-block;
    padding-left: 22px;
    cursor: pointer;
    &:before,
    &:after {
      position: absolute;
      content: "";
      display: inline-block;
    }
    &:before {
      height: 12px;
      width: 12px;
      border: 1px solid $radio-border-color;
      left: 0px;
      top: 5px;
      border-radius: 50%;
    }
    &:after {
      height: 8px;
      width: 8px;
      left: 3px;
      top: 8px;
      background: $radio-fill-color;
      border-radius: 50%;
      transform: scale(0, 0);
      transition: transform 200ms ease-in-out;
    }
  }
}

.ter-radio-group {
  padding: spacing("xxs") 0;
  .ter-radio {
    padding: 0;
  }
}
