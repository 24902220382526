@import "../../../global-styles/terra.scss";

.ter-list-feat-eight {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: auto;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;

  @media screen and (max-width: 1086px) {
    justify-content: space-around !important;
  }


    .ter-card {
    width: 250px;
    margin: 24px 0px 48px;
    padding: 8px 8px 8px 0px;
    background-color: color("white");
    box-shadow: none !important;

    @media screen and (max-width: 600px) {
      margin: 24px 0 24px;
    }

    &__image {
      -webkit-box-flex: 0;
          -ms-flex: 0;
              flex: 0;
    }
    &__body{
      padding: 32px 0px;
    }
  }
}
