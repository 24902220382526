@import "../../../global-styles/terra.scss";

.ter-list-feat-main {
  padding: spacing("super") calc((100vw - 1200px) / 2);
  margin: auto;
  position: relative;
  overflow: hidden;

  &--custom {
    max-width: 100vw;
    overflow: scroll;
  }

  &__sub-header-holder {
    img {
      width: 100%;
    }
  }

  &__sub-header,
  &__sub-header-holder {
    @include opensans-light();
    padding-bottom: 50px !important;
  }

  &--modal-deployed {
    overflow: visible;
  }

  &__background-image {
    position: absolute;
  }

  &__cta-section {
    text-align: center;

    .ter-button {
      margin: 0 24px 48px 24px;
    }
  }

  @media screen and (max-width: 1272px) {
    padding: spacing("super") 36px;
  }

  @media screen and (max-width: 600px) {
    padding: spacing("super") 36px;
  }
  @media screen and (max-width: 360px) {
    padding: spacing("super") 0;
    width: 100%;
    max-width: 360px;
  }

  &--theme-1 {
    background: color("gray-1");
  }
  &--theme-2 {
    background: color("gray-2");
  }
  &--theme-3 {
    background: color("gray-3");
  }

  &--theme-4 {
    .ter-list-feat-main {
      &__header,
      &__sub-header,
      &__sub-header-holder p ~ ul li {
        color: color("gray-3");
      }
      &__sub-header-holder p {
        color: color("gray-3");
      }
    }
    background: color("brand-2");
  }
  &--theme-5 {
    .ter-list-feat-main {
      &__header,
      &__sub-header,
      &__sub-header-holder p ~ ul li {
        color: color("gray-3");
      }
      &__sub-header-holder p {
        color: color("gray-3");
      }
    }
    background: color("brand-1");
  }

  &__header {
    margin: auto;
    @include header-standard-size;
    text-align: center;
    @media screen and (max-width: 1086px) {
      text-align: center !important;
    }
  }

  &__sub-header,
  &__sub-header-holder p {
    padding: 16px 16px 16px;
    margin: auto;
    text-align: center;
    font-weight: font-weight("light");
    @include subheader-standard-size;
  }

  .ter-list-feat-main__header,
  .ter-list-feat-main__sub-header,
  .ter-list-feat-main__sub-header-holder {
    opacity: 1;
    -webkit-transition: opacity 0.2s;
    -o-transition: opacity 0.2s;
    transition: opacity 0.2s;
  }

  .ter-variable-card {
    .ter-variable-card__header,
    .ter-variable-card__text,
    .ter-button,
    .luna-video-feat__thumbnail {
      opacity: 1;
      -webkit-transition: opacity 0.2s;
      -o-transition: opacity 0.2s;
      transition: opacity 0.2s;
    }
  }

  &--modal-deployed {
    overflow-y: visible;

    .ter-list-feat-main__header,
    .ter-list-feat-main__sub-header,
    .ter-list-feat-main__sub-header-holder {
      opacity: 0;
    }

    .ter-variable-card {
      .ter-variable-card__header,
      .ter-variable-card__text,
      .ter-button,
      .luna-video-feat__thumbnail {
        opacity: 0;
      }
    }
  }
}

.list-feat-main-9{
  display: flex;
  background-color: #f3f3f7;
  .ter-list-feat-main__header{
    margin:initial;
  }
  h2{
    font-size: 1.2em;
    font-family:
      "helvetica", "Open Sans Bold", -apple-system,
      BlinkMacSystemFont, "helvetica neue", ubuntu, roboto, noto, "segoe ui", arial, sans-serif;
  }
  div:first-child{
    display: flex;
  }
  > * {
    width: 50%;
  }
}

@media screen and (max-width: 800px) {
  .ter-list-feat-main__header,
  .ter-list-feat-main__sub-header {
    margin: 0;
  }
}

@media screen and (max-width: 728px){
  .list-feat-main-9{
    flex-direction: column;
    align-items: center;
  }
}
