@import "../../../global-styles/terra.scss";

.ter-feat-seven {
  padding-top: 48px;
  &__header {
    @include header-standard-size;
    @include opensans-very-bold();
  }

  &__header,
  &__sub-header {
    text-align: center;
    max-width: 930px;
    margin-left: auto;
    margin-right: auto;
    font-size: 28px;
    @include opensans-medium();
    line-height: 1.4;

    @media screen and (max-width: 960px) {
      max-width: 610px;
    }

    @media screen and (max-width: 640px) {
      margin-left: 0;
      margin-right: 0;
      padding: 0 32px;
    }
    @media screen and (max-width: 600px) {
      font-size: 24px;
    }
  }

  &__sub-header {
    @include subheader-standard-size;
    font-weight: 200;
    padding-bottom: 0;
  }

  .ter-tabs {
    margin-top: 36px;
  }

  &--theme-1,
  &--theme-2,
  &--theme-3,
  &--theme-4,
  &--theme-5 {
    .ter-card {
      &__header,
      &__text,
      &__sub-header {
        color: color("gray-7");
      }
    }
  }
}
