@import "../global-styles/terra.scss";

.ter-accordion {
  margin-bottom: spacing("sm");
  border-bottom: $accordion-border-width solid $accordion-border-color;
  width: 100%;
  &__fold {
    font-size: $accordion-font-size;
    margin-bottom: spacing("sm");
    border-color: $accordion-border-color;
    border-width: $accordion-border-width;
    border-style: solid;
    border-left-width: 0px;
    border-right-width: 0px;
    transition: background 225ms ease-in-out;
    margin-bottom: 0;
    border-bottom: 0px;
    &:first-child {
      border-bottom: 0px;
    }
    &:last-child {
      border-bottom: 0px;
    }
  }

  &__fold:focus {
    outline: none;
  }

  &__trigger {
    font-size: $accordion-font-size;
    padding: spacing("xs") spacing("lg") spacing("xs") spacing("sm");
    position: relative;
    display: block;
    text-decoration: none;
    @include opensans-medium();
    width: 100%;
    text-align: left;
    line-height: line-height("loose");
    &-caret {
      position: absolute;
      color: $accordion-arrow-color;
      top: 50%;
      right: spacing("sm");
      transform: rotate(0deg) translateY(-50%);
      transition: transform 200ms ease-in-out;
      font-size: 16px;
      width: 16px;
      height: 16px;
    }
    &:hover {
      cursor: pointer;
      text-decoration: none;
    }
  }

  &__trigger.tabFocus:focus {
    outline: #06f auto 2px;
  }

  &__trigger:focus {
    outline: none;
  }

  &__content {
    font-size: $accordion-font-size;
    padding: spacing("xs") spacing("sm");
    overflow-y: hidden;
    p {
      font-size: $accordion-font-size;
    }
  }
  &__fold {
    &.is-expanded {
      .ter-accordion__content {
      }
      .ter-accordion__trigger {
        text-decoration: none;
        &-caret {
          transform: rotate(180deg) translateY(50%);
        }
      }
    }
  }
}
