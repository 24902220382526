@import "../global-styles/terra.scss";

.ter-search-bar {
  @include vendor-prefix-display-flex;
  &__input {
    @include input-base();
    @include vendor-prefix-flex-1;
  }
  &__submit {
    @include input-base();
    width: initial;
    margin-left: 1px;
    &:hover {
      cursor: pointer;
    }
  }
}