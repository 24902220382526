@import "../../../global-styles/terra.scss";

$event-card-border: 1px solid color("gray-4");

.mep-event-card {
  min-height: 135px;
  border-top: $event-card-border;
  border-bottom: $event-card-border;
  margin-top: 40px;

  display: -ms-grid;

  display: grid;
  -ms-grid-columns: 140px 115px 1fr 40px;
  grid-template-columns: 140px 115px 1fr 40px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

  @media screen and (max-width: 900px) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    max-width: 256px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 40px auto;
  }

  &__date-section {
    height: calc(100% - 35px);
    border-right: $event-card-border;

    @media screen and (max-width: 900px) {
      height: 95px;
      margin-top: 20px;
    }
  }

  &__date,
  &__year {
    font-size: 12px;
    line-height: 14px;
    margin: 0 20px;
    text-align: end;

    @media screen and (max-width: 900px) {
      padding-right: 16px;
      margin: 0;
    }
  }

  &__date {
    margin-bottom: 10px;
    @include opensans-medium();
  }

  &__image {
    width: 95px;
    height: 95px;
    margin-left: 20px;
    display: block;

    @media screen and (max-width: 900px) {
      height: 95px;
      margin-top: 20px;
    }
  }

  &__details {
    margin: 0 20px;
    margin: 17px 20px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  &__industry {
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 8px;
    color: #9d9da6;
  }

  &__name {
    font-size: 16px;
    line-height: 20px;
    @include opensans-medium();
  }

  &__description {
    font-size: 16px;
    line-height: 19px;
    color: #363545;
    font-weight: 300;
  }

  &__location {
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 0;
  }

  .ter-icon {
    -webkit-transition: 0.3;
    -o-transition: 0.3;
    transition: 0.3;

    &:hover {
      -webkit-transform: scale(1.2);
      -ms-transform: scale(1.2);
      transform: scale(1.2);
    }
    @media screen and (max-width: 900px) {
      margin-bottom: 24px;
      -webkit-transform: translateX(100px);
      -ms-transform: translateX(100px);
      transform: translateX(100px);

      &:hover {
        -webkit-transform: scale(1) translateX(100px);
        -ms-transform: scale(1) translateX(100px);
        transform: scale(1) translateX(100px);
      }
    }
  }
}
