@import "../global-styles/terra.scss";

.ter-tooltip {
  display: inline-block;
  position: relative;
  &:hover {
    cursor: pointer;
  }
  &__content {
    @include z-index(1);
    box-shadow: box-shadow(1);
    font-size: $tooltip-font-size;
    display: none;
    background-color: $tooltip-bg;
    padding: spacing("xs");
    max-width: 300px;
    position: absolute;
    border-radius: $tooltip-border-radius;
    color: $tooltip-text-color;
    [data-direction="up"] & {
      bottom: calc(100% + #{spacing("mini")});
      left: 50%;
      transform: translateX(-50%);
    }
    [data-direction="left"] & {
      top: 50%;
      transform: translateY(-50%);
      right: calc(100% + 12px);
      // change to top on small screens
      @include for-size(tablet-landscape-down) {
        top: unset;
        right: unset;
        bottom: calc(100% + #{spacing("mini")});
        left: 50%;
        transform: translateX(-50%);
      }
    }
    [data-direction="down"] & {
      top: calc(100% + #{spacing("mini")});
      left: 50%;
      transform: translateX(-50%);
    }
    [data-direction="right"] & {
      top: 50%;
      transform: translateY(-50%);
      left: calc(100% + 12px);
      // change to bottom on small screens
      @include for-size(tablet-landscape-down) {
        top: calc(100% + #{spacing("mini")});
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .ter-tooltip:hover & {
      display: block;
      transform-origin: 100% 0%;
    }
    &:before {
      position: absolute;
      width: 0;
      height: 0;
      border: 10px solid transparent;
      content: "";
      z-index: 10;
      [data-direction="up"] & {
        border-top-color: $tooltip-bg;
        border-bottom: none;
        left: 50%;
        transform: translateX(-50%);
        bottom: calc(-1 * #{spacing("mini")});
      }
      [data-direction="left"] & {
        @include for-size(tablet-landscape-up) {
          border-left-color: $tooltip-bg;
          border-right: none;
          top: 50%;
          transform: translateY(-50%);
          right: calc(-1 * #{spacing("mini")});
        }
        // change to top on small screens
        @include for-size(tablet-landscape-down) {
          border-top-color: $tooltip-bg;
          border-bottom: none;
          left: 50%;
          transform: translateX(-50%);
          bottom: calc(-1 * #{spacing("mini")});
        }
      }
      [data-direction="down"] & {
        border-bottom-color: $tooltip-bg;
        border-top: none;
        left: 50%;
        transform: translateX(-50%);
        top: calc(-1 * #{spacing("mini")});
      }
      [data-direction="right"] & {
        @include for-size(tablet-landscape-up) {
          border-right-color: $tooltip-bg;
          border-left: none;
          top: 50%;
          transform: translateY(-50%);
          left: calc(-1 * #{spacing("mini")});
        }
        // change to bottom on small screens
        @include for-size(tablet-landscape-down) {
          border-bottom-color: $tooltip-bg;
          border-top: none;
          left: 50%;
          transform: translateX(-50%);
          top: calc(-1 * #{spacing("mini")});
        }
      }
    }
    p {
      font-size: $tooltip-font-size;
      margin-bottom: 0;
    }
  }
}
