@import "../global-styles/terra.scss";

.ter-logo-dumpling {
  width: 260px;
  margin: 0 spacing("xs") spacing("lg");

  &__image {
    width: 260px;
    height: 56px;
    margin: 0;
  }

  &__text {
    padding-top: spacing("mini");
    margin: 0 auto;
    text-align: center;
  }
}
