@import "../global-styles/terra.scss";
@import "../Button/Button.scss";

.ter-tabs {
  @include for-size(tablet-landscape-down) {
    display: block;
    overflow-x: auto;
    overflow-y: hidden;
  }

  &__container {
    display: flex;
    justify-content: center;
  }

  &__list {
    width: fit-content;
    text-align: center;
    align-items: center;
    justify-content: center;
    border-bottom: none !important;
    border-top: none !important;
    background-color: #f9f9fb;
    list-style: none;
    margin: 0;
    padding: 0;
    @include vendor-prefix-display-flex;
    @include vendor-prefix-justify-content-center;
    @include vendor-prefix-flex-wrap-row;

    @media screen and (max-width: 900px) {
      display: block;
      width: 100%;
      border-bottom: none !important;
    }
    > li {
      padding: 0;
      margin-right: 0;
    }
  }
  &__list-item {
    border-left: none !important;
    color: #aeaeae;
    background-color: #ffffff;
    background-color: #f9f9fb;
    padding: 0;
    margin: 0 0 -1px 0;

    button {
      border-right: none !important;
      border-left: none !important;
      border-top: 0.5px solid #7e7e7e !important;
      color: #7e7e7e !important;
      background-color: #ffffff !important;
      @include button-base();
      padding: 0 48px;
      display: block;
      font-weight: $nav-tabs-font-weight;

      &:hover {
        cursor: pointer;
        color: $nav-tabs-link-hover;
      }

      @media screen and (max-width: 900px) {
        width: 100%;
        border-right: none;
        border-left: none;
      }
    }
    &:last-child {
      button {
        border-right: $nav-tabs-border-size solid
          transparentize($nav-tabs-border-color, 0.75);

        @media screen and (max-width: 900px) {
          border-right: none;
          border-left: none;
        }
      }
    }
    &.is-selected {
      background-color: #f9f9fb;
      border-top: 1px solid #ffffff !important;
      position: relative;
      bottom: 3px;

      button {
        background-color: #f9f9fb !important;
        border-top: 4px solid #0e6ba8 !important;
        color: #0e6ba8 !important;

        @media screen and (max-width: 900px) {
          border-right: none;
          border-left: none;
          background-color: $nav-tabs-active-link-bg-mobile;
        }
      }
    }
    &.is-selected + li {
      > button {
        border-left: 0px;
      }
    }
  }
  &--full-width {
    .ter-tabs {
      &__list {
        width: 100%;
        @include vendor-prefix-justify-content-space-between;
      }
      &__list-item {
        @include vendor-prefix-flex-grow-1;
        button {
          width: 100%;
        }
      }
    }
  }

  &__content {
    background: $nav-tabs-bg-theme-1;
    padding-top: spacing("sm");
    padding-bottom: spacing("sm");
  }

  &--theme-1 {
    .ter-tabs {
      &__list-item {
        button {
          @media screen and (max-width: 900px) {
            border-right: $nav-tabs-border-size solid
              $nav-tabs-border-color-theme-1;
            border-left: $nav-tabs-border-size solid
              $nav-tabs-border-color-theme-1;
          }

          &:hover {
            color: $nav-tabs-link-hover-theme-1;
          }
        }

        &:last-child {
          button {
            border-right: $nav-tabs-border-size solid
              $nav-tabs-border-color-theme-1;

            @media screen and (max-width: 900px) {
              border-left: $nav-tabs-border-size solid
                $nav-tabs-border-color-theme-1;
            }
          }
        }
      }

      &__content {
        background: $nav-tabs-bg-theme-1;
      }
    }
  }

  &--theme-2 {
    .ter-tabs {
      &__list {
        background-color: #f3f3f7;
        border-bottom: 1px solid #f3f3f7 !important;
      }

      &__list-item {
        button {
          &:hover {
            color: $nav-tabs-link-hover-theme-2;
          }
        }

        &.is-selected {
          button {
            background-color: #f3f3f7 !important;
          }
        }
      }

      &__content {
        background: $nav-tabs-bg-theme-2;
      }
    }
  }

  &--theme-3 {
    .ter-tabs {
      &__list {
        background-color: #eaeaef;
        border-bottom: 1px solid #eaeaef !important;
      }

      &__list-item {
        button {
          &:hover {
            color: $nav-tabs-link-hover-theme-3;
          }
        }

        &.is-selected {
          button {
            border-bottom: none !important;
            background-color: #eaeaef !important;
          }
        }
      }

      &__content {
        background: $nav-tabs-bg-theme-3;
      }
    }
  }

  &--theme-4 {
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: color("gray-3");
    }

    .ter-tabs {
      &__list {
        background-color: #005f9e;
        border-bottom: 1px solid #005f9e !important;
      }

      &__list-item {
        border-top: 1px solid #7e7e7e;
        button {
          border-top: none !important;
          &:hover {
            color: $nav-tabs-link-hover-theme-4;
          }
        }

        &.is-selected {
          button {
            border-top: 3px solid #009ad9 !important;
            border-bottom: none !important;
            background-color: #005f9e !important;
            color: color("gray-3") !important;

            @media screen and (max-width: 900px) {
              border-bottom: $nav-tabs-border-size solid
                $nav-tabs-border-color-theme-4;
            }
          }
        }
      }

      &__content {
        background: $nav-tabs-bg-theme-4;
      }
    }
  }

  &--theme-5 {
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: color("gray-3");
    }

    .ter-tabs {
      &__list {
        background-color: #002d5b;
        border-bottom: 1px solid #002d5b !important;
      }

      &__list-item {
        border-top: 1px solid #7e7e7e;

        button {
          border-top: none !important;
          &:hover {
            color: $nav-tabs-link-hover-theme-5;
          }
        }

        &.is-selected {
          button {
            border-top: 3px solid #009ad9 !important;
            color: color("gray-3") !important;
            border-bottom: none !important;
            background-color: #002d5b !important;
            @media screen and (max-width: 900px) {
              border-bottom: $nav-tabs-border-size solid
                $nav-tabs-border-color-theme-5;
            }
          }
        }
      }

      &__content {
        background: $nav-tabs-bg-theme-5;
      }
    }
  }
}

.ter-tabs-panel {
  &__outer {
    &--active {
      display: block;
    }

    &--inactive {
      display: none;
    }
  }

  &--theme-1 {
    background: color("gray-1");
  }
  &--theme-2 {
    background: color("gray-2");
  }
  &--theme-3 {
    background: color("gray-3");
  }
  &--theme-4 {
    background: color("brand-2");
  }
  &--theme-5 {
    background: color("brand-1");
  }

  &__wrapper {
    max-width: 1200px;
    margin: auto;
  }
}

.tabFocus:focus {
  outline: #06f auto 2px;
}
