@import "../../../global-styles/global-vars.scss";

.luna-admin-product-data {
  margin-bottom: 32px;
  p {
    font-size: 14px;
    padding: 0 0 0 6px;
    margin: 0;
  }

  &__name {
    background-color: color("gray-3");
    margin: 0;
    padding: 6px;
  }

  &__regions,
  &__translations {
    padding-left: 6px;
  }

  &__regions {
    li {
      padding-top: 6px;
    }
  }

  &__region-wrapper {
    display: flex;
    padding-top: 6px;
  }

  &__region-list {
    display: inline-flex;
    font-size: 14px;
    margin: 0;

    &-item {
      list-style: none;

      &--add-space {
        &::after {
          content: ",";
          padding-right: 4px;
        }
      }
    }
  }
}

.bold-me-pls {
  font-weight: 700;
}

.luna-translation-data {
  background: color("gray-3");
  color: color("gray-7");
  padding: 16px;
  margin: 8px;
  display: grid;
  grid-template-columns: 150px 1fr;
  align-items: center;
  a {
    color: color("gray-7") !important;
  }

  img {
    box-sizing: border-box;
    // width: 100%;
    // margin: 0 16px;
    padding: 16px 16px 16px 0;
    width: 100%;
  }
}
