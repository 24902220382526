@import "../../../global-styles/terra.scss";

.ter-navbar {
  background: $mep-gray-1;
  width: 100%;
  top: 0;
  left: 0;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  z-index: 60;
  @include opensans-normal();
  position: fixed;

  &--is-showing {
    .ter-navbar-top {
      height: 48px;
      opacity: 1;
    }
    .ter-navbar__wrapper {
      height: 64px;
      opacity: 1;
    }
  }

  &--is-hidden {
    .ter-navbar-top,
    .ter-navbar__wrapper {
      opacity: 0;
      height: 0;
      overflow: hidden;
    }
  }

  &__fullscreen-dark-backer {
    position: fixed;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.7);
    padding: 48px 0;
    height: 100vh;
    width: 100vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }

  &__wrapper {
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
    background: $mep-gray-1;

    display: -ms-grid;

    display: grid;
    -ms-grid-columns: 1fr $max-screen-width 1fr;
    grid-template-columns: 1fr $max-screen-width 1fr;

    height: 64px;
    position: relative;

    -webkit-box-align: center;

    -ms-flex-align: center;

    align-items: center;

    @media screen and (max-width: 900px) {
      display: none;
    }
  }

  &__menus-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 16px;
    max-width: $max-screen-width;

    -ms-grid-column: 2;

    grid-column: 2;
  }

  .ter-nav-mini-menu {
    height: 72px;
    display: block !important;

    &:hover {
      cursor: pointer;
    }

    &:hover .ter-nav-mini-menu__text {
      background: $mep-gray-2;
    }

    &__text {
      @include opensans-medium();
      img {
        height: 8px;
        margin: auto;
        padding-left: 6px;
      }
    }

    @-moz-document url-prefix() {
      &__text {
        flex: 1;
        position: relative;
      }
    }

    &__drop-down {
      position: absolute;
      background: $mep-gray-2;
      top: 64px;
      max-height: 0;
      overflow: hidden;
      -webkit-transition: 0.3s;
      -o-transition: 0.3s;
      transition: 0.3s;
      width: 400px;
      padding: 0 32px;
      opacity: 0;
      z-index: 60;

      &--is-open {
        max-height: 400px;
        opacity: 1;
        padding: 32px;
      }

      a {
        display: block;
        text-decoration: none;
        background-color: transparent;
        display: block;
        font-size: font-size("micro");
        color: $body-font-color;
        font-weight: 600;
        line-height: 1.4;
        padding: 8px 16px;
        text-decoration: none;
        -webkit-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;

        &:hover {
          background: $mep-blue-2;
          color: #f3f3f7;
          text-decoration: underline;
        }

        img {
          height: 12px;
          float: right;
          transform: translateY(3px);
          opacity: 0;
          transition: 0.4s;
        }

        &:hover img {
          opacity: 1;
        }
      }
    }

    &__text {
      color: $body-font-color;
      font-size: 14px;
      margin: 11px 0 0;
      padding: 10px 16px 0;
      position: relative;
      -webkit-transition: 0.4s;
      -o-transition: 0.4s;
      transition: 0.4s;
      height: 53px;
      -ms-flex-item-align: end;
      -ms-grid-row-align: end;
      align-self: end;
      border-top: 2px solid transparent;

      &--is-open {
        background: gray("gray-4");
        border-top: 2px solid $mep-blue-2;
        cursor: pointer;
      }

      .ter-icon {
        display: block;
        margin: auto;
      }
    }
  }

  &__nav-link {
    text-decoration: none;
    -webkit-transform: translateY(3px);
    -ms-transform: translateY(3px);
    transform: translateY(3px);
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    padding: 0 0 12px;
    margin-bottom: 20px;
    @include opensans-medium();
    border-top: 2px solid transparent;

    p {
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      height: 22px;
      -ms-flex-item-align: end;
      -ms-grid-row-align: end;
      align-self: end;
      font-size: 14px;
      color: $body-font-color;
      line-height: 24px;
      margin: 0;
      padding: 9px 16px;
      -webkit-transition: 0.4s;
      -o-transition: 0.4s;
      transition: 0.4s;

      text-decoration: none;
    }

    &:hover {
      text-decoration: none;
      border-top: 2px solid $mep-blue-2;
    }

    &:hover p {
    }
  }

  @-moz-document url-prefix() {
    // This will specifically target Firefox browsers and apply a different height to the <p> tag inside nav-links to apply a smaller height value
    .ter-navbar__nav-link {
      p {
        height: 20px !important;
      }
    }
  }
}

.ter-utility-nav {
  height: 48px;
  display: flex;
  align-items: center;
  color: gray("white");
  font-size: font-size("nano");
  position: relative;
  background: $mep-blue-2;
  grid-column: 2;

  @media screen and (max-width: 900px) {
    height: 32px;
    display: grid;
    grid-template-columns: 1fr 110px;
  }

  &__wrapper {
    background: $mep-blue-2;
    display: grid;
    grid-template-columns: 1fr $max-screen-width 1fr;
    border-bottom: 1px solid color("gray-7");

    @media screen and (max-width: $max-screen-width) {
      display: block;
    }
  }

  &__title-wrapper {
    display: flex;
    background: $mep-blue-2;

    @media screen and (max-width: 900px) {
      position: relative;
    }
  }

  &__page-title {
    padding: 0 32px;
    line-height: 48px;
    font-size: font-size("nano");
    margin: 0;
    @include opensans-medium();
    border-right: solid 1px gray("white");

    span {
      font-weight: font-weight("book");
    }

    @media screen and (max-width: 900px) {
      line-height: 32px;
      width: auto;
      padding: 0 0 0 16px;
      border-right: none;
    }
  }

  &__link-container {
    padding-left: 20px;
    display: flex;

    @media screen and (max-width: 1200px) {
      display: none;
    }
  }

  &__link {
    text-decoration: none;
    display: flex;
    align-items: center;
    height: 48px;

    &:hover {
      text-decoration: none;
    }
    p {
      color: gray("gray-1");
      line-height: 48px;
      font-size: font-size("nano");
      padding: 0 12px;
      margin: 0;
      transition: 0.4s;

      &:hover {
        background: $mep-blue-2;
        text-decoration: none;
      }
    }
  }

  .ter-button {
    position: absolute;
    right: 32px;
    width: 150px;
    height: 33px;
    display: block;
    text-align: center;
    line-height: 33px;
    font-size: font-size("micro");

    @media screen and (max-width: 900px) {
      display: block;
      height: 20px;
      font-size: 10px;
      line-height: 20px;
      padding: 0 6px;
      width: auto;
      flex-grow: 1;
      right: 16px;
    }
  }
}

@media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
  /* IE10+ specific styles go here */
  .ter-utility-nav {
    .ter-button--secondary--3 {
      margin-top: -20px !important;
    }
  }
}
