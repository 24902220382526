@import "../../../global-styles/terra.scss";

.luna-language-selector {
  padding: 0 0 20px 0;
  background: color("gray-1");
  max-width: 750px;
  margin: auto;
  display: flex;
  flex-direction: column;
  z-index: 300;
  //overflow-y: scroll;

  @media screen and (max-width: 800px) {
    max-width: 510px;
  }

  @media screen and (max-width: 550px) {
    max-width: 400px;
    margin-bottom: 24px;
    margin-top: 24px;

    &--default {
      transform: translateY(170px);
    }
    // default indicates that there are at least two regions
    // moves the selector down so that it doesn't peak up above the window in a non-scrollable area
  }

  &__top-section {
    display: flex;
    height: 44px;
    align-items: center;
    justify-content: space-between;
    //margin-bottom: 20px;
  }

  &__top-left-section {
    display: flex;
    height: 44px;
    padding-left: 20px;
  }

  &__globe-icon,
  &__close-icon {
    width: 20px;
  }

  &__close-btn {
    margin-right: 7px;
  }

  &__close-icon {
    &:hover {
      cursor: pointer;
    }
  }

  &__current-region {
    font-size: font-size("nano");
    line-height: 44px;
    margin: 0 0 0 12px;

    @media screen and (max-width: 550px) {
      display: block;
      line-height: 16px;
      padding-top: 6px;
    }

    span {
      @media screen and (max-width: 550px) {
        display: block;
        line-height: 16px;
      }
    }

    &-label {
      font-weight: 600;
    }
  }

  &__region-section {
    padding: 0 20px;
    column-gap: 16px;

    display: grid;
    //grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    flex-wrap: wrap;

    //@media screen and (max-width: 800px) {
    //  grid-template-columns: 1fr 1fr;
    //}
    //
    //@media screen and (max-width: 550px) {
    //  justify-content: space-around;
    //  grid-template-columns: 1fr;
    //}
  }

  &__bottom-section {
    display: flex;
    justify-content: flex-end;
    padding: 0 20px;
    //margin-top: 36px;

    @media screen and (max-width: 550px) {
      justify-content: space-around;
      display: block;
    }
  }

  &__bottom-button {
    @media screen and (max-width: 550px) {
      display: block;
      width: 100%;
    }
    &:first-child {
      margin-right: 20px;

      @media screen and (max-width: 550px) {
        margin-right: 0;
        margin-bottom: 16px;
      }
    }

    font-size: font-size("sm");
    width: 144px;
    height: 32px;
    border: solid 2px rgba(0, 0, 0, 0.4);

    &:hover {
      background: rgba(0, 0, 0, 0.4);
      border: solid 2px rgba(0, 0, 0, 0);

      color: gray("gray-2");
      cursor: pointer;
    }
  }
}
