@import "../../../global-styles/terra.scss";

.ter-hero-three {
  @include organism-padding();
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: auto;
  position: relative;
  overflow: hidden;
  @media screen and (max-width: 900px) {
    display: block;
    padding: 32px;
  }

  &--right {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
  }

  &__thumbnail {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    max-width: 100%;
    height: auto;
    display: block;
    margin: auto;
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;

    &--is-hovering {
      -webkit-transform: scale(1.03);
          -ms-transform: scale(1.03);
              transform: scale(1.03);
      cursor: pointer;
    }

    @media screen and (max-width: 900px) {
      padding: 0;
    }

    &-container {
      -webkit-box-flex: 7;
          -ms-flex: 7;
              flex: 7;
      position: relative;

      @media screen and (max-width: 900px) {
        max-width: 100vw;
      }
    }
  }

  &__play-btn {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;

    &--is-hovering {
      -webkit-transform: scale(1.03);
          -ms-transform: scale(1.03);
              transform: scale(1.03);
      cursor: pointer;
    }
  }

  &__content-container {
    -webkit-box-flex: 5;
        -ms-flex: 5;
            flex: 5;

    -ms-grid-column-align: center;

        justify-self: center;
    width: 100%;

    @media (min-width: $breakpoint-sm) {
      padding: 0 48px 0 0px;
    }

    @media (min-width: $breakpoint-md) {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
    }

    @media (max-width: 900px) {
      padding: 32px 0 0;
    }
  }

  &__header {
    @include header-standard-size;
  }

  &__text,
  &__text-holder p {
    @include subheader-standard-size;
    font-weight: font-weight("light");
    margin-top: spacing("xs");
  }

  .ter-cta-section {
    min-width: 150px;
    width: -webkit-min-content;
    width: -moz-min-content;
    width: min-content;

    .ter-button {
      margin: spacing("xs") auto 0;
      text-align: center;
      display: block;
    }

    @media screen and (max-width: 900px) {
      margin-bottom: 36px;
    }
  }

  .ter-cta-section__link {
    text-align: center;
    display: block;
    clear: both;
  }

  // VIDEO MODAL STYLING //

  &__video-wrapper {
    position: relative;

    -webkit-transition: 0.3s;

    -o-transition: 0.3s;

    transition: 0.3s;
    -webkit-transform: translateY(50px);
        -ms-transform: translateY(50px);
            transform: translateY(50px);

    &--default {
      padding-bottom: 56.25%; /* 16:9 */
      padding-top: 25px;
      height: 0;
    }

    &--is-deployed {
      -webkit-transform: translateY(0);
          -ms-transform: translateY(0);
              transform: translateY(0);
    }

    iframe {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      max-width: 90%;
      max-height: 90%;
      margin: auto;
      @media screen and (max-height: 750px) {
        max-height: 100vh;
        margin: 0 auto;
      }
    }
  }

  &__modal {
    position: fixed;
    height: 100vw;
    width: 100vw;
    background: rgba(0, 0, 0, 0.5);
    z-index: 100;
    top: 0;
    left: 0;
    overflow: hidden;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    opacity: 0;
    -webkit-animation: fade-in 0.5s linear;
            animation: fade-in 0.5s linear;
    height: 100vh;
    width: 100vw;
    opacity: 0;
    visibility: hidden;
    display: -ms-grid;
    display: grid;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;

    &--is-deployed {
      opacity: 1;
      visibility: visible;
    }
  }

  &--theme-1 {
    @include theme-1;
    .ter-cta-section {
      .ter-button {
        color: --color-brand-2;
      }
    }
  }
  &--theme-2 {
    background: color("gray-2");
    @include theme-2;
    .ter-cta-section {
      .ter-button {
        color: --color-brand-2;
      }
    }
  }
  &--theme-3 {
    background: color("gray-3");
    @include theme-3;
    .ter-cta-section {
      .ter-button {
        color: --color-brand-2;
      }
    }
  }
  &--theme-4 {
    background: color("brand-2");
    h1,
    h2,
    h3,
    h4,
    p {
      @include brand-theme-text;
    }
    a.ter-button.ter-button--primary--1 {
      background-color: color("gray-2");
      color: color("brand-1");
    }
    a.ter-button.ter-button--primary--1:hover {
      background-color: color("gray-4") !important;
      color: color("brand-1") !important;
    }
    a.ter-button.ter-button--secondary--1 {
      border: 1px solid color("gray-2");
      color: color("gray-2") !important;
    }
    a.ter-button.ter-button--secondary--1:hover {
      background-color: color("gray-4") !important;
      color: color("brand-1") !important;
    }

    .ter-cta-section__link {
      @include brand-theme-link-text;
    }
    a {
      @include brand-link-text-deco;
    }
  }
  &--theme-5 {
    background: color("brand-1");
    h1,
    h2,
    h3,
    h4,
    p {
      @include brand-theme-text;
    }

    a.ter-button.ter-button--primary--1 {
      background-color: color("gray-2");
      color: color("brand-1");
    }
    a.ter-button.ter-button--primary--1:hover {
      background-color: color("gray-4") !important;
      color: color("brand-1") !important;
    }
    a.ter-button.ter-button--secondary--1 {
      border: 1px solid color("gray-2");
      color: color("gray-2") !important;
    }
    a.ter-button.ter-button--secondary--1:hover {
      background-color: color("gray-4") !important;
      color: color("brand-1") !important;
    }
    .ter-cta-section__link {
      @include brand-theme-link-text;
    }
    a {
      @include brand-link-text-deco;
    }
  }

  &__wrapper {
    position: relative;
    &--theme-1 {
      background: color("gray-1");
    }
    &--theme-2 {
      background: color("gray-2");
    }
    &--theme-3 {
      background: color("gray-3");
    }
    &--theme-4 {
      background: color("brand-2");
    }
    &--theme-5 {
      background: color("brand-1");
    }
  }

  .ter-hero-three__header,
  .ter-hero-three__text,
  .ter-hero-three__text-holder,
  .ter-hero-three__thumbnail-container,
  .ter-hero-three__thumbnail,
  .ter-button {
    opacity: 1;
    -webkit-transition: opacity 0.2s;
    -o-transition: opacity 0.2s;
    transition: opacity 0.2s;
  }

  &--deployed {
    overflow: visible;

    .ter-hero-three__header,
    .ter-hero-three__text,
    .ter-hero-three__text-holder,
    .ter-hero-three__thumbnail-container,
    .ter-hero-three__thumbnail,
    .ter-button {
      opacity: 0 !important;
    }
  }
}