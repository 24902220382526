@import "../../../global-styles/terra.scss";

.ter-variable-card {
  position: relative;
  width: 288px;
  margin: 16px 16px 32px;
  z-index: 1;
  background: transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-shadow: none;
  box-shadow: none;
  &[href] {
    display: block;
  }

  &--deployed {
    overflow: visible;
  }

  .variable-content-img {
    width: 288px;
    padding: 0;
  }
  img {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding: 0;
  }

  &__text {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    font-size: 16px;
  }

  &__body {
    font-size: $card-font-size;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 32px 0 0 0;
    p {
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      font-size: $card-font-size;
    }

    &__header {
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      font-size: font-size("base");
    }

    &__sub-header {
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      margin: 0;
      margin-bottom: 16px;
      @include opensans-medium();
    }
  }

  &__image {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin: spacing("none");
    padding: spacing("none");

    img {
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      width: 100%;
      height: auto;
    }
  }

  .ter-button {
    width: 100%;
    display: block;
    bottom: 0;
    margin-top: auto;
  }

  &--theme-4 {
    .ter-variable-card__text-holder {
      a {
        color: white !important;
      }
    }
    .ter-variable-card__header {
      color: color("gray-1");
    }

    @include theme-4;
  }
  &--theme-5 {
    .ter-variable-card__text-holder {
      a {
        color: white !important;
      }
    }

    .ter-variable-card__header {
      color: color("gray-1");
    }

    @include theme-5;
  }
}

.luna-video-feat {
  text-align: center;
  font-family: $font-sans-serif;
  padding: 96px 0;
  margin: auto;
  position: relative;

  &__header {
    color: #262533;
    font-size: font-size("xl");
    @include opensans-very-bold();
    line-height: 1.2;
    margin: 0 0 38px;
  }

  &__sub-header {
    font-size: font-size("base");
    line-height: 1.6;
    margin: 0 0 30px;
  }

  &__text-content {
    padding: 0 32px;
    max-width: 752px;
    margin: auto;
  }

  &__thumbnail,
  .variable-content-img {
    background-size: cover;
    height: 162px;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    vertical-align: top;
    width: 288px;
    margin: auto;
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;

    &--is-hovering {
      -webkit-transform: scale(1.03);
      -ms-transform: scale(1.03);
      transform: scale(1.03);
      cursor: pointer;
    }

    &-container {
      position: relative;
    }
  }

  &__play-btn {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;

    &--is-hovering {
      -webkit-transform: scale(1.03);
      -ms-transform: scale(1.03);
      transform: scale(1.03);
      cursor: pointer;
    }
  }

  &__video-wrapper {
    position: relative;

    -webkit-transition: 0.3s;

    -o-transition: 0.3s;

    transition: 0.3s;
    -webkit-transform: translateY(50px);
    -ms-transform: translateY(50px);
    transform: translateY(50px);
    z-index: 100;

    &--default {
      padding-bottom: 56.25%; /* 16:9 */
      padding-top: 25px;
      height: 0;
    }

    &--is-deployed {
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0);
    }

    .__modal-image {
      max-width: 1060px;
      height: auto;
      width: 100%;
    }

    iframe,
    .__modal-image {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      max-width: 1060px;
      max-height: 600px;
      margin: auto;
      @media screen and (max-height: 650px) {
        max-height: 100vh;
        margin: 0 auto;
      }
    }
  }

  &__modal {
    position: fixed;
    height: 100vw;
    width: 100vw;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    top: 0;
    left: 0;
    overflow: hidden;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    opacity: 0;
    -webkit-animation: fade-in 0.5s linear;
    animation: fade-in 0.5s linear;
    height: 100vh;
    width: 100vw;
    opacity: 0;
    visibility: hidden;
    display: -ms-grid;
    display: grid;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    &--is-deployed {
      opacity: 1;
      visibility: visible;
    }
    iframe {
      margin: 3% auto;
      @media screen and (max-height: 650px) {
        max-height: 100vh;
        margin: 0 auto;
      }
    }
  }

  &__background-image {
    position: absolute;
  }
}
