@import "../../../global-styles/terra.scss";

.ter-navbar-top {
  background: $mep-gray-0;
  height: 48px;
  padding: 0 32px;
  display: grid;
  grid-template-columns: 250px 1fr 600px;
  z-index: 51;
  position: relative;
  max-width: $max-screen-width;
  grid-column: 2;
  transition: 0.4s;
  overflow: hidden;

  @media screen and (max-width: 900px) {
    grid-template-columns: 140px 1fr 50px;
    padding: 0 16px;
    height: 48px;
  }

  &__wrapper {
    background: $mep-gray-0;

    display: grid;
    grid-template-columns: 1fr $max-screen-width 1fr;

    @media screen and (max-width: $max-screen-width) {
      display: block;
    }
  }

  &__left {
    display: flex;
    align-items: center;
  }

  &__logo {
    max-height: 20px;
    margin-top: -1px;

    &-link {
      max-height: 20px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__division-name {
    margin: 0;
    padding: 0 0 0 4px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-weight: font-weight("book");
    color: $mep-blue-2;
    font-size: 18px;
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px);
    white-space: nowrap;

    span {
      display: block;
      -webkit-transform: translateX(-6px) translateY(-2px);
      -ms-transform: translateX(-6px) translateY(-2px);
      transform: translateX(-6px) translateY(-2px);
    }
  }

  &__right {
    grid-column: 3;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @media screen and (max-width: 900px) {
      display: none;
    }

    &--mobile {
      display: none;
      grid-column: 3;

      @media screen and (max-width: 900px) {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }

      &-hamburger {
        height: 12px;
        width: 18px;
        position: relative;
        // margin-right: 32px;

        &:hover {
          cursor: pointer;
        }

        &-top,
        &-middle,
        &-bottom {
          background: $mep-gray-3;
          height: 2px;
          width: 18px;
          position: absolute;
          transition: 0.4s;
        }

        &-middle {
          top: 5px;
        }

        &-bottom {
          top: 10px;
        }

        &--is-open .ter-navbar-top__right--mobile-hamburger-top {
          transform: rotate(45deg);
          top: 5px;
        }

        &--is-open .ter-navbar-top__right--mobile-hamburger-middle {
          transform: rotate(45deg);
        }

        &--is-open .ter-navbar-top__right--mobile-hamburger-bottom {
          transform: rotate(-45deg);
          top: 5px;
        }
      }
    }
  }

  &__location {
    font-size: font-size("nano");
    line-height: 36px;
    margin: 0;

    &:hover {
      cursor: pointer;
    }
  }

  &__globe {
    height: 15px;
    padding-left: 12px;
    padding-right: 32px;

    &:hover {
      cursor: pointer;
    }
  }

  .luna-nav-search__input {
    height: 36px;
  }

  &__search {
    height: 15px;

    &-wrapper {
      width: 28px;
      height: 28px;
      margin-right: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: solid 4px transparent;
      box-sizing: content-box;

      &:hover {
        cursor: pointer;
      }

      &--deployed {
        background: $mep-blue-1;
      }

      &--hover {
        background: $mep-blue-2;
      }
    }
  }

  &__link {
    font-size: font-size("nano");
    line-height: 36px;
    margin: 0;
    padding-right: 48px;

    &:visited {
      color: #363545;
    }

    &:last-of-type {
      padding-right: 0;
    }
  }

  a {
    color: #363545;
  }
}

@media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
  /* IE10+ specific styles go here */
  .ter-navbar-top {
    &__division-name {
      position: relative;
      top: 7px;
    }
    &__right {
      margin-left: 100px;
    }
    &__search-wrapper, &__search {
      margin-bottom: 5px;
    }
  }
}

@media screen and (max-width: 500px) {

  .ter-top-mobile-nav__search-icon {
    padding: 8px !important;

  }
}
