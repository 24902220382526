@import "../../../global-styles/terra.scss";

.ter-list-feat-six {
  width: 100%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  &__card {
    padding: 32px;
    margin: 16px;
    background: #F3F3F7;
    min-width: 200px;
    max-width: 500px;
    min-height: 100px;
    vertical-align: center;
    img {
      width: 100%;
    }
  }

  &--theme-1 {
      background: color("gray-1");
    } 
    &--theme-2 {
      background: color("gray-2");
      .ter-list-feat-six {
         &__card {
         background: #F9F9FB
        }
      }
    } 
    &--theme-3 {
      .ter-list-feat-six {
         &__card {
         background: #F9F9FB
        }
      }

      background: color("gray-3");
    }
    &--theme-4 {
      background: color("brand-2");
      .ter-list-feat-six {
         &__card {
         background: #F9F9FB
        }
      }
  
    }
    &--theme-5 {
      .ter-list-feat-six {
         &__card {
         background: #F9F9FB
        }
      }
        
      background: color("brand-1");
    }
}