@import "../global-styles/terra.scss";

.ter-card {
  background: $card-bg;
  position: relative;
  border-width: $card-border-width;
  border-style: solid;
  border-color: $card-border-color;
  border-radius: $card-border-radius;
  @include vendor-prefix-display-flex;
  @include vendor-prefix-flex-direction-column;
  -webkit-box-shadow: $card-box-shadow;
  box-shadow: $card-box-shadow;

  &[href] {
    display: block;
  }
  &__header {
    font-size: font-size("base");
    @include opensans-very-bold();
  }
  &__sub-header {
    margin: 0;
    margin-bottom: 16px;
    @include opensans-medium();
  }
  &__body {
    font-size: $card-font-size;
    @include vendor-prefix-display-flex;
    @include vendor-prefix-flex-1;
    @include vendor-prefix-flex-direction-column;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: spacing("md") spacing("sm");
    p {
      font-size: $card-font-size;
    }
  }
  &__image {
    margin: spacing("none");
    padding: spacing("none");

    img {
      width: 100%;
      height: auto;
    }
  }
  &__wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    img {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .ter-button {
    width: 100%;
    display: block;
    bottom: 0;
    margin-top: auto;
  }
}

@media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
  /* IE10+ specific styles go here */
  .ter-card {
    &__body {
      margin-top: 205px;
    }
  }
}
