@import "../global-styles/terra.scss";

.ter-dropdown {
  display: block;
  position: relative;
  outline: none;
  &:hover {
    cursor: pointer;
  }
  &__selected {
    @include input-base();
    outline: none;
    position: relative;
    .is-open & {
      border-color: color("ui-1");
      background-color: color("white");
    }
    &--warning {
      border-color: color("danger");
    }
  }
  &__caret {
    position: absolute;
    right: spacing("xs");
    top: 50%;
    transform: translateY(-50%);
  }
  &__options-list {
    border: $dropdown-border-size solid $dropdown-border-color;
    position: absolute;
    top: calc(100% + #{$dropdown-border-size});
    padding: 0;
    left: 0;
    list-style: none;
    background: $dropdown-bg;
    width: 100%;
    margin: 0;
    z-index: 100;
    box-sizing: border-box;
    max-height: 0;
    overflow: hidden;
    visibility: none;
    // height: 0;

    .is-open & {
      visibility: visible;
      max-height: 320px;
      // height: 100%;
      overflow: scroll;
    }
  }
  &__options-list-item {
    @include type-setting();
    font-size: $dropdown-item-font-size;
    color: $dropdown-link-color;
    padding: spacing("xxs") spacing("xs");
    display: block;
    text-decoration: none;
    &:hover {
      color: darken($dropdown-link-color, 10%);
      background: $dropdown-link-hover-bg;
      text-decoration: none;
    }
  }
}
