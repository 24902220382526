@import "../../../global-styles/terra.scss";

.ter-feat-two-b {
  margin: auto;
  position: relative;
  padding: spacing("super") calc((100vw - 1200px) / 2) !important;

  @media screen and (max-width: 1272px) {
    padding: spacing("super") 36px !important;
  }

  &__header-container {
  grid-row: 1 / span 1 ;
  grid-column: 1 / span 12;
}

&__header {
  @include header-standard-size;
  text-align: center;
}

&__sub-header,
&__sub-header-holder p {
  @include subheader-standard-size;
  font-weight: font-weight("light");
  text-align: center;
}

  @media (min-width: 900px) {
    @include grid-container;
  }

  &__image-wrapper {
    margin-bottom: 0;

    @media (min-width: 900px) {
      @include grid-column(6);
    }
  }

  &__ratio-wrapper {
    position: relative;
    overflow: hidden;
    .ter-feat-two-b__image--desktop,
    .ter-feat-two-b__image--mobile {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  &__content-container-header-present {

  }

  &__content-container {
    padding-top: 16px;

    @media (min-width: 900px) {
      @include grid-column(6);
      padding-left: 16px;
      padding-top: 0;
    }

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }

  &__content-container-header-present {
    @media (max-width: 950px) {
      padding-top: 70px !important;
    }

     @media(max-width: 900px) {
      display: block !important;
      align-items: center;
      justify-content: center;
      grid-column: 1 / span 12;
    }

    @media(max-width: 600px) {
      padding: 140px 0 0 20px;
    }

    @media(max-width: 500px) {
      padding: 200px 0 0 20px;
    }
  }

  &__image {
    width: 100%;

    &--desktop {
      display: block;
      @media screen and (max-width: 900px) {
        display: none;
      }
    }

    &--mobile {
      display: none;
      @media screen and (max-width: 900px) {
        display: block;
      }
    }

    &--mobile-header-present {
      @media(max-width: 900px) {
        height: auto;
        width: 500px;
        margin: 0 auto;
        padding-top: 32px;
      }

      @media(max-width: 710px) {
        padding-top: 80px;
      }

      @media(max-width: 600px) {
        padding-top: 100px;
        max-width: 400px;
      }

      @media(max-width: 500px) {
        padding-top: 140px;
      }

      @media(max-width: 475px) {
        max-width: 350px;
      }

      @media(max-width: 420px) {
        padding-top: 200px;
        max-width: 250px;
      }
    }

  }

  &__image-wrapper-header-present {
    @media(max-width: 900px) {
      width: 100%;
      grid-column: 1 / span 12;
    }
  }

  &__image-header-present {
      margin-top: 32px;
      
      @media(max-width: 1070px) {
        margin-top: 60px;
      }  
  }

  & .ter-icon-list-item {
    display: block;

    @media (min-width: $breakpoint-xs) {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }

    &__text {
    }

    &:last-child .ter-icon-list-item__text {
      margin-bottom: 0;
    }
  }

  &__text {
    text-align: center;
    padding-bottom: 32px;

    @media (min-width: 900px) {
      margin-bottom: 0;
    }
  }

  .ter-icon-list-item {
    padding: 0;

    &__header {
      margin-top: spacing("mini");
      @include header-standard-size;
    }

    &__body,
    &__body-wrapper p {
      margin-top: spacing("xxs");

      @media (min-width: 900px) {
        margin-bottom: 0;
      }
    }

    .ter-icon {
      margin-top: spacing("mini");
    }
  }

  .ter-link-card {
    margin-top: spacing("md");

    &__text {
      font-size: font-size("base");
    }

    &__header {
      @include header-standard-size;
    }

    &__link {
      font-size: font-size("base");
    }

    .ter-icon {
      -webkit-transform: translateY(9px) scale(0.7);
          -ms-transform: translateY(9px) scale(0.7);
              transform: translateY(9px) scale(0.7);
    }
  }

  &--light {
    @include light-theme;
  }
  &--dark {
    @include dark-theme;
  }

  &--theme-1 {
    @include theme-1;
    background: color("gray-1");

    h2,
    h3,
    h4,
    button {
      color: color("gray-7");
    }
  }

  &--theme-2 {
    @include theme-2;
    background: color("gray-2");

    h2,
    h3,
    h4,
    button {
      color: color("gray-7");
    }
  }

  &--theme-3 {
    @include theme-3;

    h2,
    h3,
    h4,
    button {
      color: color("gray-7");
    }
    background: color("gray-3");
  }

  &--theme-4 {
    @include theme-4;
    h2,
    h3,
    h4,
    button {
      color: color("gray-1");
    }

    .ter-icon-list-item {
      svg {
        @include brand-theme-fill;
      }
    }
    background: color("brand-2");
  }

  &--theme-5 {
    @include theme-5;
    background: color("brand-1");

    h2,
    h3,
    h4,
    button {
      color: color("gray-1");
    }

    .ter-icon-list-item {
      svg {
        @include brand-theme-fill;
      }
    }
  }
}
