@import "../../../global-styles/terra.scss";

.ter-hero-2 {
  &__hero-image {
    width: 100%;
    height: auto;
    max-height: 600px;
    margin: 0;
    padding: 0;
    img {
      vertical-align: middle;
      width: 100%;
      height: auto;
      display: block;
    }

    &--with-motion {
      -webkit-animation: fade-in 0.5s;
              animation: fade-in 0.5s;
    }

    &--4x1 {
      display: none;
      @media screen and (min-width: $breakpoint-sm) {
        display: block;
      }
    }
    &--2x1 {
      display: block;
      @media screen and (min-width: $breakpoint-sm) {
        display: none;
      }
    }
  }

  .ter-card__body {
    background: white;
  }

  &__container {
    @include container-wrap;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: auto;
    -webkit-transform: translate(0, calc(#{spacing("xxl")} * -1));
        -ms-transform: translate(0, calc(#{spacing("xxl")} * -1));
            transform: translate(0, calc(#{spacing("xxl")} * -1));
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    position: relative;
    @media screen and (min-width: $breakpoint-xs) {
      -webkit-transform: translate(0, calc(#{spacing("super")} * -1));
          -ms-transform: translate(0, calc(#{spacing("super")} * -1));
              transform: translate(0, calc(#{spacing("super")} * -1));
    }

    &--with-motion {
      -webkit-animation: fade-up 1s;
              animation: fade-up 1s;
    }
  }
  &__card {
    background-color: rgba(255, 255, 255, 0.95);
    display: block;
    -ms-flex-preferred-size: 550px;
        flex-basis: 550px;
    margin-top: auto;
    margin-bottom: calc(#{spacing("md")} * -1);
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
    @media screen and (min-width: $breakpoint-xs) {
      margin-bottom: calc(#{spacing("sm")} * -1);
    }
  }
  &__header {
    @include header-standard-size;
    text-align: center;
  }
  &__sub-header {
    @include subheader-standard-size;
    font-weight: font-weight("light");
    margin-bottom: spacing("md");
    text-align: center;
  }

  .ter-button {
    text-align: center;
    display: block;
  }

  .ter-cta-section {
    margin: auto;
  }

  &__cta-section-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }


  &--theme-1 {
    @include theme-1;
  }
  &--theme-2 {
    background: color("gray-2");
    @include theme-2;
  }
  &--theme-3 {
    background: color("gray-3");
    @include theme-3;
  }
  &--theme-4 {
    background: color("brand-2");
  }
  &--theme-5 {
    background: color("brand-1");
  }
}
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fade-up {
  0% {
    opacity: 0;
    top: 100px;
  }
  30% {
    opacity: 0;
    top: 100px;
  }
  100% {
    opacity: 1;
    top: 0;
  }
}

@keyframes fade-up {
  0% {
    opacity: 0;
    top: 100px;
  }
  30% {
    opacity: 0;
    top: 100px;
  }
  100% {
    opacity: 1;
    top: 0;
  }
}