@import "../global-styles/terra.scss";

.ter-icon-list-item {
  @include vendor-prefix-display-flex;
  padding: spacing("xs");

  &__icon-wrapper {
    margin-right: spacing("xs");
  }

  &__header {
    font-size: font-size("md");
    @include opensans-very-bold();
  }

  .ter-icon {
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
  }

  &--theme-1 {
    h4,
    p {
      color: color("gray-7");
    }
    background: color("gray-1");
  }
  &--theme-2 {
    h4,
    p {
      color: color("gray-7");
    }
    background: color("gray-2");
  }
  &--theme-3 {
    h4,
    p {
      color: color("gray-7");
    }
    background: color("gray-3");
  }
  &--theme-4 {
    h4,
    p {
      color: color("gray-1");
    }
    background: color("brand-2");
    .ter-icon {
      fill: color("gray-1");
    }
  }
  &--theme-5 {
    h4,
    p {
      color: color("gray-1");
    }
    background: color("brand-1");
    .ter-icon {
      fill: color("gray-1");
    }
  }
}
