@import "../../../global-styles/terra.scss";

.luna-feat-five {
  padding: spacing("super") calc((100vw - 1200px) / 2);
  text-align: center;
  font-family: $font-sans-serif;
  padding: 96px 0;
  margin: auto;
  position: relative;
  overflow: hidden;

  &--theme-1 {
    background: color("gray-1");
  }
  &--theme-2 {
    background: color("gray-2");
  }
  &--theme-3 {
    background: color("gray-3");
  }
  &--theme-4 {
    background: color("brand-2");
  }
  &--theme-5 {
    background: color("brand-1");
  }

  &__header {
    @include header-standard-size;
    @include opensans-very-bold();
    line-height: 1.2;
    margin: 0 0 38px;
  }

  &__sub-header {
    @include subheader-standard-size;
    font-weight: font-weight("light");
    line-height: 1.6;
    margin: 0 0 30px;
  }

  &__text-content {
    padding: 0 32px;
    max-width: 752px;
    margin: auto;
  }

  &__thumbnail-container {
    padding: 32px;
  }

  &__thumbnail {
    max-width: 752px;
    min-width: 688px;
    max-height: 387px;
    -o-object-fit: cover;
    object-fit: cover;
    display: block;
    margin: auto;
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;

    @media screen and (max-width: 740px) {
      max-width: 600px;
      min-width: 600px;
      min-height: 387px;
      max-height: 387px;
    }

    @media screen and (max-width: 655px) {
      max-width: 550px;
      min-width: 550px;
      min-height: 309px;
      max-height: 309px;
    }

    @media screen and (max-width: 610px) {
      max-width: 500px;
      min-width: 500px;
      min-height: 281px;
      max-height: 281px;
    }

    @media screen and (max-width: 555px) {
      max-width: 450px;
      min-width: 450px;
      min-height: 253px;
      max-height: 253px;
    }

    @media screen and (max-width: 510px) {
      max-width: 400px;
      min-width: 400px;
      min-height: 225px;
      max-height: 225px;
    }

    @media screen and (max-width: 455px) {
      max-width: 350px;
      min-width: 350px;
      min-height: 197px;
      max-height: 197px;
    }

    @media screen and (max-width: 410px) {
      max-width: 325px;
      min-width: 325px;
      min-height: 182px;
      max-height: 182px;
    }

    @media screen and (max-width: 380px) {
      max-width: 275px;
      min-width: 275px;
      min-height: 155px;
      max-height: 155px;
    }

    &--is-hovering {
      -webkit-transform: scale(1.03);
      -ms-transform: scale(1.03);
      transform: scale(1.03);
      cursor: pointer;
    }

    &-container {
      position: relative;
    }
  }

  &__play-btn {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;

    &--is-hovering {
      -webkit-transform: scale(1.03);
      -ms-transform: scale(1.03);
      transform: scale(1.03);
      cursor: pointer;
    }
  }

  &__video-wrapper {
    position: relative;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    -webkit-transform: translateY(50px);
    -ms-transform: translateY(50px);
    transform: translateY(50px);
    z-index: 100;

    &--default {
      padding-bottom: 56.25%; /* 16:9 */
      padding-top: 25px;
      height: 0;
    }

    &--is-deployed {
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0);
    }

    iframe {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      max-width: 1060px;
      max-height: 600px;
      margin: 3% auto;
      @media screen and (max-height: 650px) {
        max-height: 100vh;
        margin: 0 auto;
      }
    }
  }

  &__modal {
    position: fixed;
    height: 100vw;
    width: 100vw;
    background: rgba(0, 0, 0, 0.5);
    z-index: 100;
    top: 0;
    left: 0;
    overflow: hidden;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    opacity: 0;
    -webkit-animation: fade-in 0.5s linear;
    animation: fade-in 0.5s linear;
    height: 100vh;
    width: 100vw;
    opacity: 0;
    visibility: hidden;
    display: -ms-grid;
    display: grid;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    &--is-deployed {
      opacity: 1;
      visibility: visible;
    }
  }

  &--theme-1 {
    @include theme-1;
  }
  &--theme-2 {
    @include theme-2;
  }
  &--theme-3 {
    @include theme-3;
  }
  &--theme-4 {
    @include theme-4;

    h1,
    h2,
    h3,
    h4,
    p {
      @include brand-theme-text;
    }
  }
  &--theme-5 {
    @include theme-5;

    h1,
    h2,
    h3,
    h4,
    p {
      @include brand-theme-text;
    }
  }

  &__background-image {
    position: absolute;
    opacity: 1;

    &--hidden {
      opacity: 0;
    }
  }

  &__wrapper {
    position: relative;
    overflow: hidden;
  }

  .luna-feat-five__header,
  .luna-feat-five__sub-header,
  .luna-feat-five__thumbnail-container,
  .luna-feat-five__thumbnail {
    -webkit-transition: opacity 0.2s;
    -o-transition: opacity 0.2s;
    transition: opacity 0.2s;
  }

  &--deployed {
    overflow-y: visible;

    .luna-feat-five__header,
    .luna-feat-five__sub-header,
    .luna-feat-five__thumbnail-container,
    .luna-feat-five__thumbnail {
      opacity: 0;
    }
  }
}
