// header type setting
@mixin header-type-setting() {
  font-size: 1em;
  font-family: $header-font-family;
  line-height: line-height("tight");
  font-weight: $header-font-weight;
  color: $header-font-color;
  display: block;
  margin: 0 0 0.67em 0;
}

// general type setting
@mixin type-setting($font-size: "base", $line-height: "regular") {
  font-size: font-size($font-size);
  line-height: line-height($line-height);
}

// paragraph type setting
@mixin paragraph-type-setting {
  @include type-setting("base", "loose");
  margin: 0 0 1.5em 0;
}

// sets font-size
$normal-font-family: "Open Sans", -apple-system, BlinkMacSystemFont,
  "helvetica neue", helvetica, ubuntu, roboto, noto, "segoe ui", arial,
  sans-serif;

$light-font-weight: 100;
$light-font-family: "Open Sans Light", -apple-system, BlinkMacSystemFont,
  "helvetica neue", helvetica, ubuntu, roboto, noto, "segoe ui", arial,
  sans-serif;

$medium-font-weight: 400;
$medium-font-family: "Open Sans Medium", -apple-system, BlinkMacSystemFont,
  "helvetica neue", helvetica, ubuntu, roboto, noto, "segoe ui", arial,
  sans-serif;

$very-bold-font-family: "Open Sans ExtraBold", -apple-system,
  BlinkMacSystemFont, "helvetica neue", helvetica, ubuntu, roboto, noto,
  "segoe ui", arial, sans-serif;

@mixin font-size($size) {
  @include type-setting($size);
}

// NEW BENTING FONT STYLING
@mixin opensans-normal() {
  font-family: $normal-font-weight;
  font-kerning: normal !important;
}

@mixin opensans-light() {
  font-family: $light-font-family;
  font-weight: $light-font-weight;
  font-kerning: normal !important;
}

@mixin opensans-medium() {
  font-family: $medium-font-family;
  font-weight: $medium-font-weight;
  font-kerning: normal !important;
}

@mixin opensans-very-bold() {
  font-family: $very-bold-font-family;
  font-kerning: normal !important;
}

h3 {
  @include opensans-very-bold();
  font-kerning: normal !important;
}
