@import "../../../global-styles/terra.scss";

.ter-list-feat-nine {
  display:flex;
  flex-wrap: wrap;
  a {
    text-decoration: none;
    font-size: 0.8rem;
    border-bottom: 1px;
    border-color: #eaeaef;
    border-style: solid;
    width: 100%;
  }
  a:first-child{
    border-top: 1px;
    border-color: #eaeaef;
    border-style: solid;
  }
}
