@import "../../../global-styles/terra.scss";

.ter-list-feat-three {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: auto;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;

  .ter-card {
    width: 288px;
    margin: 24px 16px 48px;
    background-color: color("white");

    @media screen and (max-width: 600px) {
      margin: 24px 0 48px;
    }

    &__image {
      -webkit-box-flex: 0;
          -ms-flex: 0;
              flex: 0;
    }
  }
}