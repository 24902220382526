@import "../../../global-styles/terra.scss";

.ter-list-feat-four {
  &__dumpling-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    margin: auto;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;

    .ter-feat-five__dumpling-wrapper {
      width: 200px;
      margin: 16px 16px 48px;
    }
  }

  &--theme-4 {
    a {
      color: white;
    }
    .ter-dumpling__icon-wrapper {
      svg {
        fill: white;
      }
    }
  }

  &--theme-5 {
    a {
      color: white;
    }
    .ter-dumpling__icon-wrapper {
      svg {
        fill: white;
      }
    }
  }
}