@import "../global-styles/terra.scss";

.ter-icon {
  width: 16px;
  height: 16px;

  &--8px {
    width: 8px;
    height: 8px;
  }

  &--16px {
    width: 16px;
    height: 16px;
  }

  &--24px {
    width: 24px;
    height: 24px;
  }

  &--32px {
    width: 32px;
    height: 32px;
  }

  &--48px {
    width: 48px;
    height: 48px;
  }

  // These are used for attaching primary/secondary colors to su-icon paths
  --primary-color: #363545;
  --secondary-color: #009ad9;
}
