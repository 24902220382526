@import "../../../global-styles/terra.scss";

.ter-feat-eight {
  padding: spacing("super") calc((100vw - 1200px) / 2);
  margin: auto;
  position: relative;
  @media screen and (max-width: 1272px) {
    padding: spacing("super") 36px;
  }

  &__header {
    @include header-standard-size;
  }
  &__sub-header {
    @include subheader-standard-size;
    font-weight: font-weight("light");
  }
  &__container {
    padding: 32px 0;
  }
  &__image {
    img {
      width: 100%;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      height: 100%;
      object-fit: cover;
    }

    &--with-motion {
      -webkit-animation: fade-in 0.5s;
      animation: fade-in 0.5s;
    }

    &--desktop {
      display: none;
      @media screen and (min-width: $breakpoint-sm) {
        display: block;
      }
    }
    &--mobile {
      display: block;
      @media screen and (min-width: $breakpoint-sm) {
        display: none;
      }
    }
  }
  &--theme-1 {
    @include theme-1;
  }
  &--theme-2 {
    background: color("gray-2");
    @include theme-2;
  }
  &--theme-3 {
    background: color("gray-3");
    @include theme-3;
  }
  &--theme-4 {
    background: color("brand-2");
    .ter-feat-eight__header,
    .ter-feat-eight__sub-header,
    .ter-feat-eight__sub-header-holder {
      @include brand-theme-text;
    }
  }
  &--theme-5 {
    background: color("brand-1");
    .ter-feat-eight__header,
    .ter-feat-eight__sub-header,
    .ter-feat-eight__sub-header-holder {
      @include brand-theme-text;
    }
  }
}
