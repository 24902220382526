@import "../global-styles/terra.scss";

.ter-pricing-card {
  @include box-shadow(2);
  padding: spacing("sm");

  &__product {
    @include opensans-light();
    text-align: center;
    margin-bottom: 0;
  }

  &__version {
    @include opensans-very-bold();
    text-transform: uppercase;
    text-align: center;
    margin-top: 0;
  }

  &__description {
    font-weight: font-weight("light");
    text-align: center;
    font-size: font-size("sm");
    margin-bottom: spacing("xs");
  }

  &__cost {
    @include opensans-very-bold();
    text-align: center;
    font-size: font-size("xl");
    margin-bottom: 0;
  }

  &__currency {
    font-weight: font-weight("light");
    text-align: center;
    font-size: font-size("micro");
  }

  .ter-button {
    margin: 0 auto;
    display: block;
  }
}
