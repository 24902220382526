@import "../../../global-styles/terra.scss";

.ter-list-feat-five {

  &__header {
    @include header-standard-size;
  }
  
  &__content-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    margin: auto;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;

    .ter-icon-list-item {
      max-width: 400px;
      margin: 48px 0 0 0;
      padding: 16px;
    }
  }

  &--theme-4 {
    background: color("brand-2");
    .ter-icon-list-item {
      &__header, p {
        color: white;
      }
    }
  }

  &--theme-5 {
    background: color("brand-1");
    .ter-icon-list-item {
      &__header, p {
        color: white;
      }
    }
  }
}