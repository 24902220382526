@import "../../../global-styles/terra.scss";

.ter-feat-two-a {
  padding: spacing("super") calc((100vw - 1200px) / 2) !important;
  margin: auto;
  position: relative;

  &__header {
    @include header-standard-size;
    text-align: center;
  }

  &__sub-header,
  &__sub-header-holder p {
    @include subheader-standard-size;
    font-weight: font-weight("light");
    text-align: center;
  }

  &__background-image {
    position: absolute;
  }

  &__image--mobile {
    margin: auto;
    display: block;
    width: 100%;
    height: auto;

    @media (min-width: $breakpoint-sm) {
      display: none;
    }
  }
  &__mobile-wrapper {
    display: block;
    width: 100%;
    height: auto;
    position: relative;
    @media (min-width: $breakpoint-sm) {
      display: none;
    }
  }
  &__image-wrapper {
    position: relative;
  }

  &__image--mobile,
  &__image-desktop {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    object-fit: cover;
  }

  &__header {
    text-align: center;
    @include header-standard-size;
    max-width: 800px;
    margin: spacing("md") auto;
  }

  &__text,
  &__text-holder p {
    max-width: 800px;
    margin: spacing("md") auto;
    text-align: center;
  }

  &__accordion-image-container {
    padding: 0 !important;
    @media (min-width: $breakpoint-sm) {
      -ms-flex-pack: distribute;
          justify-content: space-around;
      @include grid-container;
    }
  }

  &__accordion-wrapper {
    @media (min-width: $breakpoint-sm) {
      padding-left: 16px;
      width: 100%;
      @include grid-column(6);
      -ms-flex-item-align: center;
          -ms-grid-row-align: center;
          align-self: center;
    }

    .ter-accordion {
      -ms-flex-item-align: start;
          -ms-grid-row-align: start;
          align-self: start;
    }
  }

  &__image {
    margin: auto;
    display: block;
    width: 100%;
  }

  &__image-wrapper {
    display: none;

    width: 100%;
    height: auto;
    margin: auto;

    @media (min-width: $breakpoint-sm) {
      display: block;
      @include grid-column(6);
    }

    @media (min-width: $breakpoint-sm) {
      padding-bottom: 0;
    }
  }

  &--theme-1 {
    background: color("gray-1");

    @include theme-1;

    h3,
    h4 {
      color: color("gray-7");
    }
  }

  &--theme-2 {
    @include theme-2;
    background: color("gray-2");

    h3,
    h4 {
      color: color("gray-7");
    }
  }

  &--theme-3 {
    @include theme-3;
    background: color("gray-3");

    h3,
    h4 {
      color: color("gray-7");
    }
  }

  &--theme-4 {
    @include theme-4;
    background: color("brand-2");

    h2,
    h3,
    h4,
    button {
      @include brand-theme-text;
    }
    .ter-accordion {
      @include brand-theme-border;
      &__trigger-caret {
        @include brand-theme-fill;
      }
      &__fold {
        @include brand-theme-border;
      }
    }
  }

  &--theme-5 {
    @include theme-5;
    background: color("brand-1");

    h2,
    h3,
    h4,
    button {
      @include brand-theme-text;
    }
    .ter-accordion {
      @include brand-theme-border;
      &__trigger-caret {
        @include brand-theme-fill;
      }
      &__fold {
        @include brand-theme-border;
      }
    }
  }
}
