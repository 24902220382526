@import "../../../global-styles/terra.scss";

.ter-list-feat-two {
  &__dumpling-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    margin: auto;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;

    .ter-headshot-dumpling {
      width: 250px;
      margin: 32px 16px 48px;
    }
  }

  &--theme-4 {
    p {
      color: white;
    }
  }

  &--theme-5 {
    p {
      color: white;
    }
  }
}