@import "../global-styles/terra.scss";

.ter-cta-row {
  @include vendor-prefix-display-flex;
  @include vendor-prefix-justify-content-space-between;
  width: 424px;
  padding-top: 32px;

  @media (max-width: 600px) {
    display: block;
    padding-top: 16px;
    width: 100%;
  }

  .ter-button {
    width: 200px;
    display: block;

    @media (max-width: 600px) {
      width: 100%;
      margin-top: 16px;
    }
  }
}